<template>
  <div class="uk-container full-page">
    <UserHeader />
    <h1>Add Missing Kit Id</h1>
    <form @submit.prevent="register">
      <div v-if="message" class="success">
        {{ message }}
      </div>
      <div v-if="error">
        <p class="error">{{ error }}</p>
      </div>
      <div class="uk-margin uk-width-1-3">
        <label for="kitId">Kit Id</label>
        <input
          class="uk-input"
          type="text"
          v-model="kitId"
          @input="formatKitId"
          placeholder="XXXXXX-XXXXXX"
          :class="{ 'error': !isKitIdValid && submitted }"
        />
        <p v-if="!isKitIdValid && submitted" class="error-message">Kit Id must be in the format XXXXXX-XXXXXX with only uppercase letters.</p>
      </div>
      <div class="uk-margin uk-width-1-3">
        <label for="serialNumber">Serial Number</label>
        <input
          class="uk-input"
          type="text"
          v-model="serialNumber"
          placeholder="99999999"
          :class="{ 'error': !isSerialNumberValid && submitted }"
        />
        <p v-if="!isSerialNumberValid && submitted" class="error-message">Serial Number must be digits.</p>
      </div>
      <div class="uk-margin uk-width-1-3">
        <label class="uk-form-label" for="form-stacked-select">Test Kit Type</label>
        <div class="uk-form-controls">
          <select class="uk-select" v-model="testKitType" :class="{ 'error': !testKitType && submitted }" id="form-stacked-select">
            <option value="" disabled>Select Kit Type</option>
            <option value="Human">Human</option>
            <option value="Pet">Pet</option>
          </select>
        </div>
      </div>        
      <div class="uk-margin-top">
        <button class="uk-button uk-button-primary" @click="addKitId" :disabled="loading">
          Add
          <span v-if="loading" class="uk-margin-small-left" uk-spinner="ratio: 0.6"></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue';
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Add Missing Kit Id | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  data() {
    return {
      kitId: '',
      serialNumber: '',
      testKitType: '',
      loading: false,
      error: '',
      message: '',
      submitted: false,
      isAuthenticated: false,
      cid: '',
      accessToken: ''
    };
  },
  components: {
    UserHeader
  },
  computed: {
    isKitIdValid() {
      const regex = /^[A-Z0-9]{6}-[A-Z0-9]{6}$/;
      return regex.test(this.kitId);
    },
    isSerialNumberValid() {
      const regex = /^[0-9]+$/;
      return regex.test(this.serialNumber);
    }
  },
  methods: {
    formatKitId() {
      this.kitId = this.kitId.toUpperCase();
    },
    async addKitId() {
      this.submitted = true;
      if (!this.isKitIdValid || !this.isSerialNumberValid || !this.testKitType) return;

      this.loading = true;
      this.message = '';
      this.error = '';

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/add-kit-id`, {
          serialNumber: this.serialNumber,
          kitId: this.kitId,
          testKitType: this.testKitType
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to add test kit id.');
        }

        this.message = 'Kit Id Added Successfully.';
        this.kitId = '';
        this.serialNumber = '';
        this.testKitType = '';
      } catch (error) {
        console.error('Error:', error);
        this.error = `Error: ${error.response?.data?.error || error.message}`;
      } finally {
        this.loading = false;
        this.submitted = false;
      }
    },
    convertUtcToLocal
  }
};
</script>
