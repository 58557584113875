<template>
  <div id="add-item-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>
      <h2 class="uk-modal-title">Add Item</h2>
      <p>TimeWaver name will be used when the Friendly Item Name is empty. Human Description will be used for pet test results when Pet Description is empty.</p>
      <div>
        <div v-if="serverResponse" class="uk-margin-bottom">
          <div :class="serverResponseType">
            {{ serverResponse }}
          </div>
        </div>

        <form @submit.prevent="submit">
          <div class="uk-margin">
            <label>TimeWaver Name <span class="uk-text-danger">*</span></label>
            <input v-model="itemName" class="uk-input" :class="{ 'uk-form-danger': errors.itemName }" />
            <div v-if="errors.itemName" class="uk-text-danger">{{ errors.itemName }}</div>
          </div>
          <div class="uk-margin">
            <label>Friendly Item Name</label>
            <input v-model="itemNameFriendly" class="uk-input" />
          </div>
          <div class="uk-margin">
            <label>Human Description</label>
            <textarea v-model="itemDescription" class="uk-textarea" rows="5" cols="50"></textarea>
          </div>
          <div class="uk-margin">
            <label>Pet Description</label>
            <textarea v-model="itemDescriptionPet" class="uk-textarea" rows="5" cols="50"></textarea>
          </div>
          <div class="uk-margin">
            <label>Category <span class="uk-text-danger">*</span></label>
            <select v-model="selectedCategory" class="uk-select" :class="{ 'uk-form-danger': errors.selectedCategory }">
              <option value="" disabled>Select a category</option>
              <option v-for="category in categories" :key="category.ItemCategoryId" :value="category.ItemCategoryId">
                {{ category.CategoryName }}
              </option>
            </select>
            <div v-if="errors.selectedCategory" class="uk-text-danger">{{ errors.selectedCategory }}</div>
          </div>
          <div class="uk-margin">
            <label>Group <span class="uk-text-danger">*</span></label>
            <select v-model="selectedGroup" class="uk-select" :class="{ 'uk-form-danger': errors.selectedGroup }">
              <option value="" disabled>Select a group</option>
              <option v-for="group in groups" :key="group.ItemGroupId" :value="group.ItemGroupId">
                {{ group.GroupName }}
              </option>
            </select>
            <div v-if="errors.selectedGroup" class="uk-text-danger">{{ errors.selectedGroup }}</div>
          </div>
          <button type="submit" class="uk-button uk-button-primary">Add Item</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
    this.fetchCategories();
    this.fetchGroups();
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      itemName: '',
      itemNameFriendly: '',
      itemDescription: '',
      itemDescriptionPet: '',
      selectedCategory: null,
      selectedGroup: null,
      categories: [],
      groups: [],
      errors: {},
      serverResponse: '',
      serverResponseType: ''
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        if (newItem) {
          this.itemName = newItem.itemName;
          this.itemNameFriendly = newItem.ItemNameFriendly;
          this.itemDescription = newItem.ItemDescription;
          this.itemDescriptionPet = newItem.ItemDescriptionPet;
          this.serverResponse = '';
        }
      }
    }
  },
  methods: {
    async fetchCategories() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-items-categories`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET'
        });
        console.log (response.data.items);
        this.categories = response.data.items;
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        this.loading = false;
      }
    },  
    async fetchGroups() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-items-groups`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET'
        });
        this.groups = response.data.items;
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        this.loading = false;
      }
    },
    validateFields() {
      this.errors = {};
      if (!this.itemName) {
        this.errors.itemName = 'TimeWaver Name is required.';
      }
      if (!this.selectedCategory) {
        this.errors.selectedCategory = 'Category is required.';
      }
      if (!this.selectedGroup) {
        this.errors.selectedGroup = 'Group is required.';
      }
      return Object.keys(this.errors).length === 0;
    },
    clearForm() {
      this.itemName = '';
      this.itemNameFriendly = '';
      this.itemDescription = '';
      this.itemDescriptionPet = '';
      this.selectedCategory = null;
      this.selectedGroup = null;
      this.errors = {};
    },
    async submit() {
      if (!this.validateFields()) return;

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/add-item`, {        
          adminGuid: this.cid,
          itemName: this.itemName,
          itemNameFriendly: this.itemNameFriendly,
          itemDescription: this.itemDescription,
          itemDescriptionPet: this.itemDescriptionPet,
          itemCategoryId: this.selectedCategory,
          itemGroupId: this.selectedGroup
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        this.serverResponse = 'The item was added successfully.';
        this.serverResponseType = 'uk-alert-success';

        this.clearForm();
      } catch (error) {
        this.serverResponse = error.response ? error.response.data : error.message;
        this.serverResponseType = 'uk-alert-danger';
      }
    },
    closeModal() {
      this.clearForm();
      this.$emit('close');
    }
  }
};
</script>
