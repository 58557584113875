<template>
  <div class="uk-container full-page">
    <UserHeader />
    <h1>Upload Test Results</h1>
    <form @submit.prevent="register">
      <div v-if="message">
        <p>{{ message }}</p>
      </div>
      <div class="uk-margin uk-width-1-3">
        <label for="kitId">Kit Id</label>
        <input
          class="uk-input"
          type="text"
          v-model="kitId"
          :class="[{ 'error-border': !kitId && submitted }]"
        />
      </div>
      <div class="uk-margin">
        <label for="csvContent">Test Result (CSV)</label>
        <textarea
          v-model="csvContent"
          class="uk-textarea"
          id="w3review"
          name="w3review"
          rows="10"
          cols="50"
          :class="[{ 'error-border': !csvContent && submitted }]"
        ></textarea>
      </div>
      <div class="uk-margin-top">
        <button class="uk-button uk-button-primary" @click="uploadTestResults" :disabled="loading">
          Upload
          <span v-if="loading" class="uk-margin-small-left" uk-spinner="ratio: 0.6"></span>
        </button>
      </div>
    </form>
    <div v-if="results" class="uk-margin-top">
      <h4>Previous Import Status</h4>
      <div v-for="result in results" :key="result.row">
        <div :style="{ color: result.success ? 'green' : 'red' }">
          {{ result.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue';

export default {
  created() {
    document.title = 'Upload Test Results | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
    this.isAuthenticated = !!this.accessToken && !!this.cid;
  },
  components: {
    UserHeader
  },
  data() {
    return {
      csvContent: '',
      kitId: '',
      results: null,
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false,
      message: null,
      submitted: false
    };
  },
  methods: {
    async uploadTestResults() {
      this.submitted = true;

      if (!this.kitId || !this.csvContent) {
        this.message = 'Kit ID and CSV content are required.';
        return;
      }

      this.loading = true;
      this.message = null;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/admin/upload-test-results`,
          {
            csv: this.csvContent,
            kitId: this.kitId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.accessToken}`,
              'Accept': 'application/json',
              'cid': this.cid
            }
          }
        );

        if (response.status === 200) {
          this.results = response.data.results;
          this.message = 'Test Results Uploaded.';
          this.kitId = '';
          this.csvContent = '';
        } else {
          this.message = response.data.error || 'Upload failed. Please try again later.';
        }
      } catch (error) {
        console.error('Upload failed:', error);
        this.message = 'Upload failed. Please try again later.';
      } finally {
        this.loading = false;
        this.submitted = false;
      }
    }
  }
};
</script>
