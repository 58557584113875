<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Customer Activities</h1>
      <div class="uk-grid-small uk-child-width-expand@s" uk-grid>
        <div class="uk-width-1-5">
          <select class="uk-select" v-model="selectedFilter" @change="onFilterChange">
            <option value="all">All Date Ranges</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last7days">Last 7 Days</option>
            <option value="last30days">Last 30 Days</option>
            <option value="custom">Custom Date Range</option>
          </select>
        </div>

        <!-- Custom Date Range: From and To Inputs on the Same Row -->
        <div v-if="selectedFilter === 'custom'" class="uk-width-2-5">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="startDate" @change="validateDates" />
            </div>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="endDate" @change="validateDates" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>

      <div v-if="!loading && items.length">
        <div class="uk-grid-divider uk-child-width-expand@s uk-grid" uk-grid>
          <div v-if="chartOptions">
          <v-chart v-if="chartOptions" :options="chartOptions"  :series="chartOptions.series" height="400" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import ApexCharts from 'vue3-apexcharts';

export default {
  created() {
    document.title = 'Activities | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader,
    'v-chart': ApexCharts
  },
  data() {
    return {
      chartOptions: null,
      items: [],
      selectedFilter: 'all',
      startDate: '',
      endDate: '',
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  watch: {
    items: {
      handler(newItems) {      
        if (Array.isArray(newItems) && newItems.length > 0) {
          this.chartOptions = this.getChartOptions(newItems);
        }
      },
      immediate: true
    }
  },
  methods: {
    onFilterChange() {
      if (this.selectedFilter === 'custom') {
        this.startDate = this.getCurrentDate();
        this.endDate = this.getCurrentDate();
      }
      this.fetchItems();
    },
    getChartOptions(items) {
        // Ensure valid data is present and `items` is defined
        if (!Array.isArray(items) || items.length === 0) {
          console.error('Invalid items array:', items);
          return;
        }

        // Define activity label mapping for ActivityTypeId
        const activityLabelMap = {
          1: 'Register Account',
          3: 'Log In',
          6: 'Kit Registration',
          7: 'Test Report View'
        };

        // Determine how the data is grouped (daily, weekly, or monthly)
        const groupedDates = [...new Set(
          items.map(item => {
            if (item.GroupedDate) {
              return item.GroupedDate; // For daily data
            } else if (item.Year && item.Week) {
              return `${item.Year}-W${String(item.Week).padStart(2, '0')}`; // Weekly data
            } else if (item.Year && item.Month) {
              return `${item.Year}-${String(item.Month).padStart(2, '0')}`; // Monthly data
            }
          })
        )];

        const activityTypes = [1, 3, 6, 7];
        const seriesData = activityTypes.map(typeId => {
          const activityData = items
            .filter(item => item.ActivityTypeId === typeId)
            .map(item => ({
              date: item.GroupedDate || (item.Year && item.Week
                ? `${item.Year}-W${String(item.Week).padStart(2, '0')}`
                : `${item.Year}-${String(item.Month).padStart(2, '0')}`),
              count: item.ActivityCount
            }));

          const counts = groupedDates.map(date => {
            const activity = activityData.find(a => a.date === date);
            return activity ? activity.count : 0;
          });

          return {
            name: `${activityLabelMap[typeId]}`,
            data: counts
          };
        });

        if (seriesData.length === 0 || groupedDates.length === 0) {
          console.error('No valid series data or grouped dates for the chart.');
          return null;  // Prevent rendering an invalid chart
        }

        return {
          chart: {
            type: 'line',
            height: 400
          },
          series: seriesData, // Pass the series data to ApexCharts
          xaxis: {
            categories: groupedDates, // X-axis will show grouped dates
            labels: {
              rotate: 45 // Rotate dates for readability
            }
          },
          yaxis: {
            title: {
              text: 'Activity Count'
            }
          },
          legend: {
            position: 'top'
          },
          tooltip: {
            enabled: true,
            x: {
              formatter: function (val) {
                return val; // Customize format if needed
              }
            }
          }
        };
      },
    async fetchItems() {
      this.loading = true;
      try {
        let params = {
          filter: this.selectedFilter
        };

        if (this.selectedFilter === 'custom') {
          params.startDate = this.startDate;
          params.endDate = this.endDate;
        }
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/activities-customer`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: params
        });

        if (response.data && response.data.items) {
          this.items = response.data.items;
        } else {
          this.items = [];
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>

<style scoped>
/* Add necessary styling if required */
</style>
