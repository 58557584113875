<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Kit Ids</h1>
      <div class="uk-width-1-4 uk-position-relative">
        <input v-model="searchTerm" @keyup.enter="fetchItems" placeholder="Kit Id" class="uk-input uk-margin-bottom-small" />
        <button v-if="searchTerm" @click="clearSearch" class="clear-button">×</button>        
      </div>
      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th class="table-width-50">Kit Id</th>
            <th class="table-width-50">Serial Number</th>
            <th class="table-width-50">Kit Type</th>
            <th class="table-width-50">TypeName</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.KitId">
            <td class="table-width-50">{{ item.SerialNumber }}</td>
            <td class="table-width-50">{{ item.KitId }}</td>
            <td class="table-width-50">{{ item.TestKitType }}</td>
            <td class="table-width-50">{{ item.TypeName }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="totalPages && totalPages > 1" class="pagination-controls">
        <button class="uk-button uk-button-secondary uk-margin-right-small" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <button class="uk-button uk-button-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div v-if="totalPages && totalPages > 1" class="uk-text-small uk-margin-top">
        Page {{ currentPage }} of {{ formattedTotalPages }} ({{formattedTotalItems}} records)
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Kit Ids | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      searchTerm: '',
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/kit-ids`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: {
            page: this.currentPage,
            limit: 20,
            search: this.searchTerm
          }
        });
        this.items = response.data.items;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalItems;
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchItems();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchItems();
      }
    },
    clearSearch() {
      this.searchTerm = '';
      this.fetchItems();
    },
    convertUtcToLocal
  },
  computed: {
    formattedTotalPages() {
      return this.totalPages.toLocaleString();
    },
    formattedTotalItems() {
      return this.totalItems.toLocaleString();
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>
