<template>
  <div class="uk-container welcome">
    <UserHeader />

    <div class="uk-text-center uk-margin-bottom">
      <h1>Page Not Found</h1>
      <p>We're sorry, but the page you were looking for doesn't exist.</p>
      <router-link to="/">Go back to Home</router-link>
    </div>
  </div>
</template>

<script>
import UserHeader from './UserHeader.vue'; 

export default {
  created() {
    document.title = 'Page Not Found | UCARI';
  },
  components: {
    UserHeader
  }
};
</script>
