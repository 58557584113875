import moment from 'moment';

export function validatePassword(password, confirmPassword) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+{}|:;<>?/\\])[A-Za-z\d!@#$%^&*()\-+{}|:;<>?/\\]{8,}$/;
  const isPasswordInvalid = !regex.test(password);
  const passwordMismatch = password !== confirmPassword ? 'Passwords do not match' : '';

  return {
    isPasswordInvalid,
    passwordMismatch
  };
}

// export function checkAuthentication() {
//   const token = localStorage.getItem('accessToken');
//   this.isAuthenticated = !!token;
//   if (this.isAuthenticated) {
//     this.accessToken = token;
//   }
// }

export function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('first');
  localStorage.removeItem('isAuthenticated');
  this.isAuthenticated = false;
  this.$router.push('/login');
}

export function toggleVisibility(currentVisibility) {
  return !currentVisibility;
}

export function convertUtcToLocal(utcDate) {
  return moment.utc(utcDate).local().format('MM/DD/YY hh:mm A');
}    
