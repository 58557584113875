<template>  
  <div class="uk-container test-kits">
    <UserHeader />
    <h1>Kit Ids</h1>
    <div v-if="filteredData">
      <div class="ucari-card" v-for="item in filteredData" :key="item.KitId">
        <div class="ucari-card-title">{{ item.FirstName || (item.PetName) }} Intolerance Kit</div>

        <div class="uk-child-width-expand@s uk-grid">        
          <div>
            <label>Name</label>
            <div>{{ item.FirstName || (item.PetName) }} {{ item.LastName }}</div>
          </div>          
          <div>
            <label>Kit Id</label>
            <div>{{ item.SerialNumber || 'N/A' }}</div>
          </div>
          <div>
            <label>Registered On</label>
            <div v-if="item.RegisteredOnUtc">
               {{ convertUtcToLocal(item.RegisteredOnUtc) }}
            </div>
            <div v-else>--</div>
          </div>
          <div>
            <label>Received On</label>
            <div v-if="item.ReceivedDateUtc">
              {{ convertUtcToLocal(item.ReceivedDateUtc) }}
            </div>
            <div v-else>--</div>
          </div>
          <div>
            <label>Result Posted</label>
            <div v-if="item.ResultDateUtc">
              {{ convertUtcToLocal(item.ResultDateUtc) }}
            </div>
            <div v-else>--</div>
          </div>
        </div>
        <div class="test-kit-status" :class="{
            complete: item.ResultDateUtc,
            received: item.ReceivedDateUtc && !item.ResultDateUtc,
            pending: !item.ReceivedDateUtc && !item.ResultDateUtc
          }">
          <div v-if="item.ResultDateUtc">Complete</div>
          <div v-else-if="item.ReceivedDateUtc && !item.ResultDateUtc">Received</div>
          <div v-else-if="!item.ReceivedDateUtc && !item.ResultDateUtc">Pending</div>
        </div>
        <div class="view-test-result uk-margin-top" v-if="item.ResultDateUtc">
          <a :href="`/test-results?id=${item.KitId}`">View Test Results</a>
        </div>
      </div>
    </div>
    <p v-else-if="isLoading">Loading...</p>
    <p v-else>No data found!</p>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  components: {
    UserHeader
  },
  name: 'TestKits',
  created() {
    document.title = 'Kit Ids | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');

    const id = this.$route.query.id;
    if (id) {
      this.searchTerm = id;
      this.fetchData();
    }
  },
  data() {
    return {
      data: [],
      isLoading: false,
      error: false,
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      searchTerm: ''      
    };
  },
  computed: {
    filteredData() {
      return this.data.items; 
    }
  },  
  methods: {
    fetchData() {
      this.isLoading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-kits`, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'cid': this.cid
        },
        params: {
          id: this.searchTerm
        }        
      })
      .then(response => {
        this.data = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        this.error = true;
        this.isLoading = false;
      });
    },    
    convertUtcToLocal
  }
}
</script>
