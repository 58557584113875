<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Test Results Views</h1>
      <div class="uk-grid-small uk-child-width-expand@s" uk-grid>
        <div class="uk-width-1-5">
          <select class="uk-select" v-model="selectedFilter" @change="onFilterChange">
            <option value="all">All Date Ranges</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last7days">Last 7 Days</option>
            <option value="last30days">Last 30 Days</option>
            <option value="custom">Custom Date Range</option>
          </select>
        </div>

        <!-- Custom Date Range: From and To Inputs on the Same Row -->
        <div v-if="selectedFilter === 'custom'" class="uk-width-2-5">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="startDate" @change="validateDates" />
            </div>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="endDate" @change="validateDates" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>

      <!-- Chart and Table -->
      <div v-if="!loading && items.length">
        <div class="uk-grid-divider uk-child-width-expand@s uk-grid" uk-grid>
          <!-- Pie Chart on the left -->
          <div v-if="chartOptions">
            <v-chart :option="chartOptions" autoresize />
          </div>

          <!-- Table on the right -->
          <div>
            <table class="uk-table uk-table-divider">
              <thead>
                <tr>
                  <th># of Views</th>
                  <th># of Customers</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in itemsWithPercentage" :key="index">
                  <td>{{ item.ViewCategory }}</td>
                  <td>{{ item.CustomerCount }}</td>
                  <td>{{ item.percentage }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

// Register ECharts components
use([PieChart, TooltipComponent, LegendComponent, CanvasRenderer]);

export default {
  created() {
    document.title = 'Activities | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader,
    'v-chart': VChart
  },
  data() {
    return {
      chartOptions: null,  // Chart options initialized as null
      items: [],        // Initialize items as an empty array
      selectedFilter: 'all',
      startDate: '',
      endDate: '',
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  computed: {
    // Calculate items with percentage to use in the table
    itemsWithPercentage() {
      const total = this.items.reduce((sum, item) => sum + item.CustomerCount, 0);
      return this.items.map(item => ({
        ...item,
        percentage: ((item.CustomerCount / total) * 100).toFixed(2) // Calculate percentage
      }));
    }
  },
  watch: {
    items: {
      handler(newItems) {
        if (newItems.length > 0) {
          this.chartOptions = this.getChartOptions(newItems);
        }
      },
      immediate: true
    }
  },
  methods: {
    getChartOptions(items) {
      const total = items.reduce((sum, item) => sum + item.CustomerCount, 0);
      const data = items.map(item => ({
        value: item.CustomerCount,
        name: `${item.ViewCategory} (${((item.CustomerCount / total) * 100).toFixed(2)}%)`
      }));

      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)' // Show percentage in tooltip
        },
        legend: {
          bottom: '10%',
          left: 'center'
        },
        series: [
          {
            name: 'View Count',
            type: 'pie',
            radius: '50%',
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    },
    getCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const dd = String(today.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
    onFilterChange() {
      if (this.selectedFilter === 'custom') {
        this.startDate = this.getCurrentDate();
        this.endDate = this.getCurrentDate();
      } else {
        this.fetchItems();
      }
    },
    validateDates() {
      if (this.startDate && this.endDate && this.endDate < this.startDate) {
        this.endDate = this.startDate;
      }
      if (this.startDate && this.endDate) {
        this.fetchItems();
      }
    },
    async fetchItems() {
      this.loading = true;
      try {
        let params = {
          filter: this.selectedFilter
        };

        if (this.selectedFilter === 'custom') {
          params.startDate = this.startDate;
          params.endDate = this.endDate;
        }
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/activities-kit-views`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: params
        });

        if (response.data && response.data.items) {
          this.items = response.data.items;
        } else {
          this.items = [];
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>

