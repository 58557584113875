<template>
  <div id="set-password-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title">Set Password</h2>
      <div v-if="serverResponse" class="uk-margin-bottom">
        <div>
          {{ serverResponse }}
        </div>
      </div>
      <div v-if="!serverResponse">
        <div class="uk-grid" v-if="customer">
          <div v-if="customer.FirstName || customer.LastName" class="columns">
            <label>Name</label>
            <div>{{ customer.FirstName }} {{ customer.LastName }}</div>
          </div>          
          <div class="columns">
            <label>Email</label>
            <div>{{ customer.Email }} ({{ customer.Id }})</div>
          </div>
        </div>
        <form @submit.prevent="submitPassword" class="form-modal">
          <div class="uk-inline uk-margin-bottom-small">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="uk-input"
              v-model="password"
              placeholder="Enter new password"
              @input="handlePasswordInput"
            />
            <a class="uk-form-icon uk-form-icon-flip" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</a>
          </div>
          <div class="password-requirements">
            <div><strong>Password must contain:</strong></div>
            <ul>
              <li :class="{ 'valid': passwordHasLowercase }">One lowercase letter</li>
              <li :class="{ 'valid': passwordHasUppercase }">One uppercase letter</li>
              <li :class="{ 'valid': passwordHasNumber }">One number</li>
              <li :class="{ 'valid': passwordHasSpecialChar }">One of these special characters: ! @ # $ % ^ & * ( ) - + { } | ?</li>
              <li :class="{ 'valid': passwordHasLength }">At least 8 characters</li>
            </ul>
          </div>
          <div class="uk-margin">
            <button class="uk-button uk-button-primary" type="submit" :disabled="isFormInvalid">Change Password & Send Email</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { validatePassword, toggleVisibility } from '@/utils/common';

export default {
  created() {
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      cid: '',
      accessToken: '',
      password: '',
      showPassword: false,
      isPasswordInvalid: false,
      serverResponse: '',
      serverResponseType: ''
    };
  },
  watch: {
    customer: {
      immediate: true,
      handler(newCustomer) {
        if (newCustomer) {
          this.cid = localStorage.getItem('adminCid');
          this.accessToken = localStorage.getItem('adminAccessToken');
        }
      }
    }
  },
  computed: {
    isFormInvalid() {
      return this.isPasswordInvalid || !this.password || !this.passwordHasLowercase || !this.passwordHasUppercase || !this.passwordHasNumber || !this.passwordHasSpecialChar || !this.passwordHasLength;
    },
    passwordHasLowercase() {
      return /[a-z]/.test(this.password);
    },
    passwordHasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    passwordHasNumber() {
      return /\d/.test(this.password);
    },
    passwordHasSpecialChar() {
      return /[!@#$%^&*()\-+{}|:;<>?/\\]/.test(this.password);
    },
    passwordHasLength() {
      return this.password.length >= 8;
    }
  },
  methods: {
    handlePasswordInput() {
      const { isPasswordInvalid } = validatePassword(this.password);
      this.isPasswordInvalid = isPasswordInvalid;
    },
    async submitPassword() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/change-password`, {
          id: this.customer.Id,
          email: this.customer.Email,
          password: this.password
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        this.serverResponse = 'Password was updated successfully, and an email was sent to the customer.';
        this.serverResponseType = 'uk-alert-success';

        console.log('Server response:', response.data);

        // UIkit.modal('#set-password-modal').hide();
      } catch (error) {
        this.serverResponse = error.response ? error.response.data : error.message;
        this.serverResponseType = 'uk-alert-danger';
        console.error('Error updating password:', this.serverResponse);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = toggleVisibility(this.showPassword);
    }
  }
};
</script>
