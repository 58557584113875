<template>
  <div>
    <nav class="uk-navbar-container uk-navbar" uk-navbar>
      <div class="uk-navbar-left">
        <a class="uk-navbar-item uk-logo logo-website" href="/">
          <img src="/images/logo.png" alt="UCARI Logo" width="200">
        </a>
        <a class="uk-navbar-item uk-logo logo-print" href="/">
          <img src="/images/logo-print.png" alt="UCARI Logo" width="200">
        </a>
      </div>
      <div v-if="isAuthenticated" class="uk-navbar-right">
        <ul class="uk-navbar-nav">
          <li :class="{ 'uk-active': isActive('/') }">
            <a href="/">Receive</a>
          </li>
          <li :class="{ 'uk-active': isActive('/upload-test-results') }">
            <a href="/upload-test-results">Upload Results</a>
          </li>
          <li :class="{ 'uk-active': isActive('/customers') }">
            <a href="/customers">Customers</a>
          </li>
          <li :class="{ 'uk-active': isActive('/test-items') }">
            <a href="/test-items">Test Items</a>
          </li>
          <li :class="{ 'uk-active': isActive('/kit-ids') }">
            <a href="/kit-ids">Kit Ids</a>
          </li>
          <li>
            <a href="#"><img src="/images/reports-menu.svg"></a>
            <div class="uk-navbar-dropdown">
              <ul class="uk-nav uk-navbar-dropdown-nav">
                <li :class="{ 'uk-active': isActive('/activities') }">
                  <a href="/activities">All Activities</a>
                </li>
                <li :class="{ 'uk-active': isActive('/activities-test-results') }">
                  <a href="/activities-test-results">Test Result Views</a>
                </li>
                <li :class="{ 'uk-active': isActive('/activities-customer') }">
                  <a href="/activities-customer">Customer Activities</a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#"><img src="/images/more-menu.svg"></a>
            <div class="uk-navbar-dropdown">
              <ul class="uk-nav uk-navbar-dropdown-nav">
                <li :class="{ 'uk-active': isActive('/add-kit-id') }">
                  <a href="/add-kit-id">Add Missing Kit Id</a>
                </li>
                <li :class="{ 'uk-active': isActive('/admins') }">
                  <a href="/admins">Admins</a>
                </li>
                <li>
                  <a @click="logout">Logout {{ firstName }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isAuthenticated: false,
      firstName: '',
      cid: '',
      accessToken: ''
    };
  },
  created() {
    this.loadCredentials();
    this.checkAuthentication();
  },
  methods: {
    loadCredentials() {
      this.accessToken = localStorage.getItem('adminAccessToken');
      this.cid = localStorage.getItem('adminCid');
      this.firstName = localStorage.getItem('first');
    },
    async checkAuthentication() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/check-auth`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          }
        });
        this.isAuthenticated = response.data.authenticated;
      } catch (err) {
        console.error('Session check failed', err);
        this.isAuthenticated = false;
      }
    },
    async logout() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/logout`);
        localStorage.removeItem('adminAccessToken');
        localStorage.removeItem('first');
        localStorage.removeItem('adminCid');
        this.isAuthenticated = false;
        this.$router.push('/login');
      } catch (err) {
        console.error('Logout failed', err);
      }
    },
    isActive(path) {
      return this.$route.path === path;
    }
  }
};
</script>
