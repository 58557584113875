<template>
  <div class="uk-container full-page">
    <UserHeader />
    Logging out...
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 

export default {
  components: {
    UserHeader
  },
  async created() {
    try {
      document.title = 'Log Out | UCARI';
      await axios.post(`${process.env.VUE_APP_API_URL}/api/logout`);
      localStorage.removeItem('adminAccessToken');
      localStorage.removeItem('adminCid');
      this.$router.push('/login');
      this.$emit('logout');
    } catch (err) {
      console.error('Logout failed', err);
    }
  }
};
</script>
