<template>
  <div class="uk-container test-results">
    <UserHeader />
    <h1><span v-if="petName">Pet</span> Intolerance Report<span v-if="this.typeName"> - {{ this.typeName }}</span></h1>
    <h3>
      <span v-if="petName">{{ this.petName }}</span>
      <span v-else>{{ this.firstName }} {{ this.lastName }}</span>
    </h3>
    <div class="uk-margin-bottom report-date">{{ convertUtcToLocal(this.resultDateUtc) }}</div>

    <div v-if="petName">
      <p>
        So, what's the deal with your results? Well, it’s important to understand that this is a list of food intolerances, not food allergies. This is actually very helpful because, according to the American Kennel Club, most pet parents think their fur baby has a food allergy when it’s really a food intolerance. Food intolerance could be the reason behind lots of symptoms your pet may have experienced like itchiness, a poor skin and coat condition, or chronic ear or foot infections. Food sensitivities and intolerances are hard to spot.
      </p>
      <div class="read-more">
        <a href="#" @click.prevent="isExpanded = !isExpanded">
          {{ isExpanded ? 'Read Less' : 'Read More' }}
        </a>
      </div>
      <div v-show="isExpanded" uk-scrollspy="cls: uk-animation-fade; repeat: true">
        <p>
          With the information we got from your pet's fur sample, we have given you a list of likely food intolerances your pet may have. We have separated them from most severely reactive to least reactive and labeled them as severe (red) and moderate (yellow) to make it easy. 
        </p>
        <p>
          In order to help your pet get better, you will probably have to change some things, like shampoo, conditioner, treats and food. When changing food, it’s important to go through the process slowly, over the course of about a week or so. After all, we want your pet to be as comfortable as possible as you make this transition. Making major dietary changes quickly can lead to upset tummies, diarrhea, vomiting, or gas. No thank you, right? So, that means you’ll need to be patient. We know you want your pet to feel better fast, but slow and steady wins the race!
        </p>
        <p>
          If your pet has been eating the same food for years, it just may be time for a change. Overexposure can also cause food sensitivities and intolerances, even if there is nothing wrong with the food itself. This goes for any ingredient, even the fancy, expensive ones!
        </p>
        <p>
          One thing for you to keep in mind is that it’s important not to get overwhelmed. When we humans take this test for ourselves, we often decide to cook everything from scratch. The difference is that we know how to meet our nutritional needs without thinking about it too hard. Dogs and cats have vastly different needs and preparing your pet's food from scratch can get complicated fast. If you want to prepare your pet's food at home, it’s important that you consult a canine or feline nutritionist to make sure that the food you are making for your pet meets their dietary needs. A poorly put together homemade diet can lead to severe nutritional deficiencies and serious health problems, so you have to be careful. There are lots of books available for pet parents who want to try homemade pet diets, but nothing beats a professional nutritionist consultation.
        </p>
        <p>
          All of these steps should help your pet start feeling their best, but if your pet has a lot of food sensitivities or is reacting very strongly with skin or tummy issues, you may have to take your pet to the vet for a checkup, just to be sure nothing else is going on. We know…the Vet can be scary! But, trust us, it’s better to make sure there aren’t any underlying issues. Some dogs and cats develop or acquire compromised immune systems that make them more sensitive to foods and environmental allergens, or they may have real food allergies that require medication or other treatments. Listening to your pet’s body and knowing exactly what’s going on will help you and your Vet pick the right treatment. Sometimes, the Vet can prescribe special hypoallergenic foods that may help.
        </p>
        <p>
          Let the wellness journey begin! Your pet is such a special part of your life, and it just wouldn’t be the same without them. Hopefully, we have done a good job translating what your pet needs so you can show them how much you love them.
        </p>
      </div>
    </div>
    <div v-else>
      <p><b>You’re on your way to happier, healthier you!</b></p>

      <p>Your personal UCARI test results are the first step toward creating the
      “New You!” At UCARI, it’s our mission to help you better understand
      your nutritional needs and deficiencies, so you can take control of your
      wellness and begin living a happier, healthier life…starting today! This results page will give you everything you need to know about severe (red) and moderate (yellow) sensitivities that your body is experiencing and how
      to modify your wellness routine to accommodate those intolerances.
      At UCARI, we’re dedicated to helping you embark upon a wellness
      journey that will last a lifetime!</p>      
    </div>

    <div class="uk-flex uk-flex-middle uk-flex-between no-print test-results-nav">
      <div class="search-box uk-flex uk-flex-middle">
        <input v-model="search" @keyup.enter="fetchItems" placeholder="Search item" class="uk-input" />
        <button v-if="search" @click="clearSearch" class="clear-button">×</button>
      </div>

      <div class="filter-box uk-flex-1 uk-flex uk-flex-center">
        <ul uk-tab>
          <li :class="{ 'uk-active': level === 'all-sensitivities' }" @click="level = 'all-sensitivities'" uk-tooltip="All Sensitivities" class="all"><a href="#">All</a></li>
          <li :class="{ 'uk-active': level === 'severe-moderate-sensitivities' }" @click="level = 'severe-moderate-sensitivities'" uk-tooltip="Severe & Moderate Sensitivities" class="severe-moderate"><a href="#">Severe & Moderate</a></li>
          <li :class="{ 'uk-active': level === 'severe-sensitivity' }" @click="level = 'severe-sensitivity'" uk-tooltip="Severe" class="severe-only"><a href="#">Severe</a></li>
          <li :class="{ 'uk-active': level === 'moderate-sensitivity' }" @click="level = 'moderate-sensitivity'" uk-tooltip="Moderate Sensitivity" class="moderate-only"><a href="#">Moderate</a></li>
          <li :class="{ 'uk-active': level === 'normal-sensitivity' }" @click="level = 'normal-sensitivity'" uk-tooltip="Normal Sensitivity" class="normal-only"><a href="#">Normal</a></li>
        </ul>
      </div>

      <div class="uk-flex-2 uk-flex uk-flex-center">
        <ul uk-tab>
          <li :class="{ 'uk-active': view === 'summary' }" @click="view = 'summary'" uk-tooltip="Summary View"><a href="#">Summary</a></li>
          <li :class="{ 'uk-active': view === 'list' }" @click="view = 'list'" uk-tooltip="List View"><a href="#">List</a></li>
        </ul>
      </div>

      <div class="uk-margin-left uk-margin-right uk-flex uk-flex-middle print-option">
        <div @click="printPage" uk-tooltip="Print Report" class="cursor-pointer uk-flex uk-flex-middle uk-margin-left print-option">
          <img src="/images/print.svg" class="action-icon" />
          <span class="uk-margin-small-left">Print</span>
        </div>
      </div>
    </div>
    
    <div v-if="loading" class="loading-spinner">
      <div uk-spinner></div>
    </div>

    <div class="uk-grid-small uk-child-width-auto@s uk-flex-middle uk-flex-center test-results-list-view-info uk-grid" uk-grid>
      <div class="uk-flex uk-flex-middle uk-first-column">
        <div class="circle">
            <img src="/images/severe.svg" alt="Severe Sensitivity">
        </div>
        <h3 class="uk-margin-remove-left">Severe Sensitivity - Avoid for a minimum of 30 Days</h3>
      </div>
      <div class="uk-flex uk-flex-middle">
        <div class="circle">
            <img src="/images/mild.svg" alt="Moderate Sensitivity">
        </div>
        <h3 class="uk-margin-remove-left">Moderate Sensitivity – Eat in moderation</h3>
      </div>
      <div class="uk-flex uk-flex-middle">
        <div class="circle">
            <img src="/images/normal.svg" alt="Normal Sensitivity">
        </div>
        <h3 class="uk-margin-remove-left">Normal Sensitivity</h3>
      </div>
    </div>

    <div v-if="view === 'summary'">
      <div 
        class="test-result-section" 
        v-if="level === 'all-sensitivities' 
          || level === 'severe-moderate-sensitivities'
          || level === 'severe-sensitivity'"
        >
        <div class="test-label-container">
          <div class="circle"><img src="/images/severe.svg" /></div>
          <h3>Severe Sensitivity</h3>
        </div>
        <div class="test-result-body">
          <div>
            These are the food and non-food items that our testing shows you could potentially have sensitivity to. <b>Click an item to view the description.</b>
          </div>

          <div class="uk-grid-match uk-child-width-expand@s" uk-grid>
            <div v-if="filteredDataSevere.length">
              <div v-for="group in filteredDataSevere" :key="group.CategoryName">
                <h4>{{ group.CategoryName }}</h4>
                <div v-if="group.items && group.items.length">
                  <div 
                    class="item" 
                    v-for="item in group.items"
                    :key="item.ItemName" 
                    :uk-tooltip="getDescription(item)">
                    {{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isLoading">Loading items...</div>
            <div class="uk-margin-top" v-else>No items found!</div>
          </div>
        </div>
      </div>
      <div 
        class="test-result-section" 
        v-if="level === 'all-sensitivities' 
          || level === 'severe-moderate-sensitivities'
          || level === 'moderate-sensitivity'">
        <div class="test-label-container">
          <div class="circle"><img src="/images/mild.svg" /></div>
          <h3>Moderate Sensitivity</h3>
        </div>
        <div class="test-result-body">
          <div>
            These are the food and non-food items that our testing shows you could potentially have sensitivity to. <b>Click an item to view the description.</b>
          </div>

          <div class="uk-grid-match uk-child-width-expand@s" uk-grid>
            <div v-if="filteredDataMild.length">
              <div v-for="group in filteredDataMild" :key="group.CategoryName">
                <h4>{{ group.CategoryName }}</h4>
                <div v-if="group.items && group.items.length">
                  <div 
                    class="item" 
                    v-for="item in group.items"
                    :key="item.ItemName" 
                    :uk-tooltip="getDescription(item)">
                    {{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isLoading">Loading items...</div>
            <div class="uk-margin-top" v-else>No items found!</div>
          </div>
        </div>
      </div>
      <div 
        class="test-result-section" 
        v-if="level === 'all-sensitivities' 
          || level === 'normal-sensitivity'"
        >
        <div class="test-label-container">
          <div class="circle"><img src="/images/normal.svg" /></div>
          <h3>Normal Sensitivity</h3>
        </div>
        <div class="test-result-body">
          <div>
            These are the food and non-food items that our testing a shows you do not have sensitivity to. <b>Click an item to view the description.</b>
          </div>

          <div class="uk-grid-match uk-child-width-expand@s" uk-grid>
            <div v-if="filteredDataNormal.length">
              <div v-for="group in filteredDataNormal" :key="group.CategoryName">
                <h4>{{ group.CategoryName }}</h4>
                <div v-if="group.items && group.items.length">
                  <div 
                    class="item" 
                    v-for="item in group.items"
                    :key="item.ItemName" 
                    :uk-tooltip="getDescription(item)">
                    {{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isLoading">Loading items...</div>
            <div class="uk-margin-top" v-else>No items found!</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="view === 'list'" class="test-results-list-view">
      <table class="uk-table uk-table-divider test-results-list">
        <thead>
          <tr>
            <th class="uk-width-small">Level</th>
            <th class="uk-width-small">Category</th>
            <th class="uk-width-small">Item</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <template 
            v-if="level === 'all-sensitivities' 
              || level === 'severe-moderate-sensitivities'
              || level === 'severe-sensitivity'"
          >
            <template v-for="category in filteredDataSevere" :key="category.CategoryName">
              <tr v-for="item in category.items" :key="item.ItemName">
                <td class="uk-width-small"><img src="/images/severe.svg" class="level" /> Severe</td>
                <td class="uk-width-small">{{ category.CategoryName }}</td>
                <td class="uk-width-small">{{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}</td>
                <td>{{ getDescription(item) }}</td>
              </tr>
            </template>
          </template>
          <template 
            v-if="level === 'all-sensitivities' 
              || level === 'severe-moderate-sensitivities'
              || level === 'moderate-sensitivity'"
          >
            <template v-for="category in filteredDataMild" :key="category.CategoryName">
              <tr v-for="item in category.items" :key="item.ItemName">
                <td class="uk-width-small"><img src="/images/mild.svg" class="level" /> Moderate</td>
                <td class="uk-width-small">{{ category.CategoryName }}</td>
                <td class="uk-width-small">{{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}</td>
                <td>{{ getDescription(item) }}</td>
              </tr>
            </template>
          </template>
          <template v-if="level === 'all-sensitivities' || level === 'normal-sensitivity'">
            <template v-for="category in filteredDataNormal" :key="category.CategoryName">
              <tr v-for="item in category.items" :key="item.ItemName">
                <td class="uk-width-small"><img src="/images/normal.svg" class="level" /> Normal</td>
                <td class="uk-width-small">{{ category.CategoryName }}</td>
                <td class="uk-width-small">{{ item.ItemNameFriendly ? item.ItemNameFriendly : item.ItemName }}</td>
                <td>{{ getDescription(item) }}</td>
              </tr>
            </template>
          </template>
        </tbody>
        </table>
    </div>

    <div class="uk-margin-top">
      View <a :href="`/all-test-items?id=${searchTerm}`">complete list of items</a> you were tested for.
    </div>

    <div class="uk-margin-top">
      <h3>UCARI Results Disclaimer</h3>
      <p>
          We are excited to provide your personalized UCARI Intolerance Test Results. As
          you review your results, it’s important to remember that the UCARI test identifies intolerances ONLY – not allergies.
      </p>
      <p>
          The terms allergy and sensitivity/intolerance are often confused and used
          interchangeably, though incorrectly. An
          allergy is not an intolerance, and vice versa. Therefore, they must be treated differently.
      </p>
      <p>
          Having a food sensitivity/intolerance may be uncomfortable and can cause
          annoying or debilitating symptoms, but
          not life-threatening ones, like those caused by a food allergy. By taking the UCARI
          test, utilizing your results, and making the
          suggested changes to your wellness routine, your newly identified intolerances
          may be reduced or eliminated over time.
      </p>
      <p>
          A food allergy tends to be a lifelong, life threatening condition. The
          physiological process, which takes place
          in the body
          during an allergic reaction, is also entirely different than an intolerance
          reaction. An allergic reaction involves the immune
          system, whereas an intolerance affects the digestive system. The UCARI test does
          not test antibody levels, so it cannot test
          for
          allergies. For example, if you have a nut allergy, but a nut sensitivity does
          not show up in your UCARI Intolerance results,
          it
          absolutely does not mean that you can resume eating nuts.
      </p>
      <p>
          If you would like more information, please consult your doctor or email
          care@ucari.com.
      </p>
      <p>
          UCARI always recommends following the advice of your medical
          practitioners.
      </p>
    </div>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  name: 'TestResults',
  created() {
    document.title = 'Test Kits | UCARI';
    this.loadCredentials();
    const id = this.$route.query.id;
    if (id) {
      this.searchTerm = id;
      this.fetchData();
    }
  },  
  components: {
    UserHeader,  
    UcariFooter
  },
  data() {
    return {
      data: [],
      groupedData: [],      
      isLoading: false,
      error: false,
      firstName: '',
      lastName: '',
      petName: '',
      searchTerm: '',
      search: '',
      cid: '',
      accessToken: '',
      view: 'summary',
      level: 'all-sensitivities',
      isExpanded: false      
    };
  },
  setup() {
    const showLogo = true;
    return { showLogo };    
  },
  computed: {
    filteredDataSevere() {
      return this.groupedData.map(group => ({
        CategoryName: group.CategoryName,
        items: group.items ? group.items.filter(item =>
          item.RValue > 89 &&
          item.ItemName.toLowerCase().includes(this.search.toLowerCase())
        ) : []
      })).filter(group => group.items.length > 0);
    },
    filteredDataMild() {
      return this.groupedData.map(group => ({
        CategoryName: group.CategoryName,
        items: group.items ? group.items.filter(item =>
          item.RValue > 69 &&
          item.RValue < 90 &&
          item.ItemName.toLowerCase().includes(this.search.toLowerCase())
        ) : []
      })).filter(group => group.items.length > 0);
    },
    filteredDataNormal() {
      return this.groupedData.map(group => ({
        CategoryName: group.CategoryName,
        items: group.items ? group.items.filter(item =>
          item.RValue < 70 &&
          item.ItemName.toLowerCase().includes(this.search.toLowerCase())
        ) : []
      })).filter(group => group.items.length > 0);
    }
  },
  methods: {
    loadCredentials() {
      this.accessToken = localStorage.getItem('adminAccessToken');
      this.cid = localStorage.getItem('adminCid');
    },
    async fetchData() {
      try {
        this.isLoading = true;

        const testKitNameResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-test-kit-name`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          params: {
            id: this.searchTerm
          }
        });

        if (testKitNameResponse.data[0]) {
          this.petName = testKitNameResponse.data[0].PetName;
          this.firstName = testKitNameResponse.data[0].FirstName;
          this.lastName = testKitNameResponse.data[0].LastName;
          this.resultDateUtc = testKitNameResponse.data[0].ResultDateUtc;
          this.testTypeId = testKitNameResponse.data[0].TestTypeId;
          this.typeName = testKitNameResponse.data[0].TypeName;
        }
        
        const testResultsResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-results`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid            
          },
          params: {
            id: this.searchTerm
          }
        });
        this.data = testResultsResponse.data;

        this.groupData();
        this.isLoading = false;
      } catch (error) {
        console.error('Error fetching items:', error.response ? error.response.data : error.message);
        this.isLoading = false;
      }
    },
    groupData() {
      const filteredData = (this.testTypeId === 5 || this.testTypeId === 9)
        ? this.data.filter(item => item.CategoryName === 'Food' || item.CategoryName === 'Nutritional')
        : this.data;

      this.groupedData = filteredData.reduce((acc, item) => {
        const group = acc.find(g => g.CategoryName === item.CategoryName);
        if (group) {
          group.items.push(item);
        } else {
          acc.push({ CategoryName: item.CategoryName, items: [item] });
        }
        return acc;
      }, []);
    },
    getDescription(item) {
      if (item) {
        if (this.petName) {
          return item.ItemDescriptionPet || item.ItemDescription;
        }
        return item.ItemDescription;
      }
      return '';
    },
    clearSearch() {
      this.search = '';
      this.fetchData();
    },
    printPage() {
      window.print();
    },
    convertUtcToLocal
  }
}
</script>