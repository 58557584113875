<template>  
  <div class="uk-container">
    <h1>Order History</h1>
    <div v-if="error" class="error-message">{{ errorMessage }}</div>
    <div v-else-if="filteredData">
      <div class="ucari-card" v-for="item in filteredData" :key="item.Id">
        <div class="ucari-card-title">Order #{{ item.Id }}</div>
        <div class="order-history-date">{{ convertUtcToLocal(item.CreatedOnUtc) }}</div>
        <div class="order-history-total">${{ item.OrderTotal.toFixed(2) }}</div>
      </div>
    </div>
    <p v-else-if="isLoading">Loading...</p>
    <p v-else>No data found!</p>
  </div>
</template>

<script>
import axios from 'axios';
import { convertUtcToLocal } from '@/utils/common';

export default {
  name: 'OrderHistory',
  data() {
    return {
      data: [],
      isLoading: false,
      error: false,
      cid: '',
      accessToken: '',      
      errorMessage: ''
    };
  },
  created() {
    this.cid = localStorage.getItem('adminCid');
    this.accessToken = localStorage.getItem('adminAccessToken');
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    filteredData() {
      return this.data; 
    }
  },  
  methods: {
    fetchData() {
      this.isLoading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/orders`, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'cid': this.cid
        }
      })
      .then(response => {
        this.data = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
        if (error.response) {
          if (error.response.status === 401) {
            // Redirect to login page if not authenticated
            this.$router.push('/login');
          } else if (error.response.status === 403) {
            // Show error message if not authorized
            this.error = true;
            this.errorMessage = 'You are not authorized to view this data.';
          } else {
            // General error message
            this.error = true;
            this.errorMessage = 'There was an error fetching the data!';
          }
        } else {
          console.error("There was an error fetching the data!", error);
          this.error = true;
          this.errorMessage = 'There was an error fetching the data!';
        }
      });
    },
    convertUtcToLocal
  }
}
</script>
