<template>
  <div id="set-activities-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title">Account Activities</h2>

      <div class="uk-grid" v-if="customer">
        <div v-if="customer.FirstName || customer.LastName" class="columns">
          <label>Name</label>
          <div>{{ customer.FirstName }} {{ customer.LastName }}</div>
        </div>
        <div class="columns">
          <label>Email</label>
          <div>
            {{ customer.Email }} 
            <span v-if="customer.Id">({{ customer.Id }})</span>
          </div>
        </div>
      </div>
      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity</th>
            <th>Kit Id</th>
            <th>Serial Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.Id">
            <td class="nowrap">
              {{ convertUtcToLocal(item.CreatedOnUtc) }}
            </td>
            <td class="nowrap">
              {{ item.Activity }}
            </td>            
            <td class="nowrap">
              <span v-if="item.KitId !== 0">
                {{ item.KitId }}
              </span>
            </td>            
            <td class="nowrap">
              <span v-if="item.SerialNumber">
                {{ item.SerialNumber }}
              </span>
            </td>            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { convertUtcToLocal } from '@/utils/common';

export default {
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      customerId: '',
      accessToken: localStorage.getItem('adminAccessToken'),
      cid: localStorage.getItem('adminCid'),
      items: [],
      loading: false
    };
  },
  watch: {
    customer: {
      immediate: true,
      handler(newCustomer) {
        if (newCustomer && newCustomer.Id) {
          this.customerId = newCustomer.Id;
          this.fetchItems();
        }
      }
    }
  },
  methods: {
    async fetchItems() {
      if (!this.customerId) return;

      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/customer-activities/${this.customerId}`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          }
        });
        this.items = response.data.items;
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    },
    
    convertUtcToLocal
  }
};
</script>
