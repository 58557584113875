<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Admins</h1>
      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th class="table-width-50">Customer Id</th>
            <th>Email</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.Id">
            <td>
              {{ item.Id }}
            </td>            
            <td>
              {{ item.Email }}
            </td>            
            <td>
              {{ item.FirstName }} {{ item.LastName }} 
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="formattedTotalItems && formattedTotalItems > 1" class="uk-text-small uk-margin-top">
        {{formattedTotalItems}} records
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Customers | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalItems: 0,
      searchTerm: '',
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/admins`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: {
            page: this.currentPage,
            limit: 20,
            search: this.searchTerm
          }
        });
        this.items = response.data.items;
        this.currentPage = response.data.currentPage;
        this.totalItems = response.data.totalItems;
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    },
    convertUtcToLocal
  },
  computed: {
    formattedTotalItems() {
      return this.totalItems.toLocaleString();
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>
