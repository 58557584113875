<template>
  <div class="uk-container test-results">
    <UserHeader />
    <div class="uk-margin-bottom no-print">
      <a :href="`/test-results?id=${searchTerm}`">&lt; Back to Report</a>
    </div>
    <div>
      <p>Below is a list of possible intolerances and nutritional imbalances you were tested for.</p>
    </div>

    <div v-if="loading" class="loading-spinner">
      <div uk-spinner></div>
    </div>
    <div class="uk-grid-match uk-child-width-expand@s" uk-grid>
      <div v-if="filteredDataAll.length">
        <div v-for="group in filteredDataAll" :key="group.CategoryName">
          <h4>{{ group.CategoryName }}</h4>
          <div v-if="group.items && group.items.length">
            <div 
              class="item" 
              v-for="item in group.items"
              :key="item.ItemName" 
              :uk-tooltip="item.ItemDescription">
              {{ item.ItemName }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading">Loading items...</div>
      <div class="uk-margin-top" v-else>No items found!</div>
    </div>

    <div class="uk-margin-top">
      <h3>UCARI Results Disclaimer</h3>
      <p>
          We are excited to provide your personalized UCARI Intolerance Test Results. As
          you review your results, it’s important to remember that the UCARI test identifies intolerances ONLY – not allergies.
      </p>
      <p>
          The terms allergy and sensitivity/intolerance are often confused and used
          interchangeably, though incorrectly. An
          allergy is not an intolerance, and vice versa. Therefore, they must be treated differently.
      </p>
      <p>
          Having a food sensitivity/intolerance may be uncomfortable and can cause
          annoying or debilitating symptoms, but
          not life-threatening ones, like those caused by a food allergy. By taking the UCARI
          test, utilizing your results, and making the
          suggested changes to your wellness routine, your newly identified intolerances
          may be reduced or eliminated over time.
      </p>
      <p>
          A food allergy tends to be a lifelong, life threatening condition. The
          physiological process, which takes place
          in the body
          during an allergic reaction, is also entirely different than an intolerance
          reaction. An allergic reaction involves the immune
          system, whereas an intolerance affects the digestive system. The UCARI test does
          not test antibody levels, so it cannot test
          for
          allergies. For example, if you have a nut allergy, but a nut sensitivity does
          not show up in your UCARI Intolerance results,
          it
          absolutely does not mean that you can resume eating nuts.
      </p>
      <p>
          If you would like more information, please consult your doctor or email
          care@ucari.com.
      </p>
      <p>
          UCARI always recommends following the advice of your medical
          practitioners.
      </p>
    </div>
  </div>
  <UcariFooter />
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import UcariFooter from './UcariFooter.vue'; 

export default {
  name: 'TestResults',
  created() {
    document.title = 'Test Kits | UCARI';
    this.loadCredentials();
    const id = this.$route.query.id;
    if (id) {
      this.searchTerm = id;
      this.fetchData();
    }
  },  
  components: {
    UserHeader,  
    UcariFooter
  },
  data() {
    return {
      data: [],
      groupedData: [],      
      isLoading: false,
      error: false,
      firstName: '',
      lastName: '',
      petName: '',
      searchTerm: '',
      search: '',
      cid: '',
      accessToken: ''
    };
  },
  setup() {
    const showLogo = true;
    return { showLogo };    
  },
  computed: {
    filteredDataAll() {
      return this.groupedData.map(group => ({
        CategoryName: group.CategoryName,
        items: group.items ? group.items : []
      })).filter(group => group.items.length > 0);
    }
  },
  methods: {
    loadCredentials() {
      this.accessToken = localStorage.getItem('adminAccessToken');
      this.cid = localStorage.getItem('adminCid');
    },
    async fetchData() {
      try {
        this.isLoading = true;

        const testResultsResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/test-results`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid            
          },
          params: {
            id: this.searchTerm
          }
        });

        this.data = testResultsResponse.data;

        this.groupData();
        this.isLoading = false;
      } catch (error) {
        console.error('Error fetching items:', error.response ? error.response.data : error.message);
        this.isLoading = false;
      }
    },
    groupData() {
      this.groupedData = this.data.reduce((acc, item) => {
        const group = acc.find(g => g.CategoryName === item.CategoryName);
        if (group) {
          group.items.push(item);
        } else {
          acc.push({ CategoryName: item.CategoryName, items: [item] });
        }
        return acc;
      }, []);
    }
  }
}
</script>