<template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Activities</h1>
      <div class="uk-grid-small uk-child-width-expand@s" uk-grid>
        <div class="uk-width-1-5">
          <input v-model="searchTerm" @keyup.enter="fetchItems" placeholder="Email" class="uk-input uk-margin-bottom-small" />
          <button v-if="searchTerm" @click="clearSearch" class="clear-button">×</button>
        </div>

        <div class="uk-width-1-5">
          <select id="action-select" class="uk-select" v-model="selectedActivityType" @change="fetchItems">
            <option value="0">All Activity Types</option>
            <option value="13">Email Verified</option>
            <option value="3">Log In</option>
            <option value="1">Register</option>
            <option value="6">Register Kit & Confirmation Email Sent</option>
            <option value="4">Reset Password Request & Email Sent</option>
            <option value="5">Reset Password & Confirmation Email Sent</option>
            <option value="2">Verify Email Sent</option>
            <option value="7">View Test Report</option>
            <option value="8">Admin - Sample Received & Confirmation Email Sent</option>
            <option value="9">Admin - Test Result Uploaded & Report Ready Email Sent</option>
            <option value="10">Admin - View Test Report</option>
            <option value="11">Admin - Password Changed & Email Sent</option>
            <option value="12">Admin - Email Verification Link Resent</option>
            <option value="14">Admin - Test Kit Id Added</option>
            <option value="15">Admin - Email Address Changed & Email Sent</option>
            <option value="16">Admin - Impersonate</option>
            <option value="17">Admin - Updated Item</option>
          </select>
        </div>

        <div class="uk-width-1-5">
          <select class="uk-select" v-model="selectedFilter" @change="onFilterChange">
            <option value="all">All Date Ranges</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last7days">Last 7 Days</option>
            <option value="last30days">Last 30 Days</option>
            <option value="custom">Custom Date Range</option>
          </select>
        </div>

        <div v-if="selectedFilter === 'custom'" class="uk-width-2-5">
          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="startDate" @change="validateDates" />
            </div>
            <div class="uk-width-1-2">
              <input class="uk-input" type="date" v-model="endDate" @change="validateDates" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th>Date</th>
            <th>Email</th>
            <th>Customer Id</th>
            <th>Name</th>
            <th>Activity</th>
            <th>Kit Id</th>
            <th>Serial Number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.Id">
            <td class="nowrap">
              {{ convertUtcToLocal(item.CreatedOnUtc) }}
            </td>
            <td>
              {{ item.Email }}
            </td>            
            <td>
              {{ item.CustomerId }}
            </td>            
            <td>
              {{ item.FirstName }} {{ item.LastName }} 
            </td>
            <td class="nowrap">
              {{ item.Activity }}
            </td>            
            <td class="nowrap">
              <span v-if="item.KitId !== 0">
                {{ item.KitId }}
              </span>
            </td>            
            <td class="nowrap">
              <span v-if="item.SerialNumber">
                {{ item.SerialNumber }}
              </span>
            </td>            
          </tr>
        </tbody>
      </table>
      <div v-if="totalPages && totalPages > 1" class="pagination-controls">
        <button class="uk-button uk-button-secondary uk-margin-right" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <button class="uk-button uk-button-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div v-if="totalPages && totalPages > 1" class="uk-text-small uk-margin-top">
        Page {{ currentPage }} of {{ formattedTotalPages }} ({{formattedTotalItems}} records)
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserHeader from './UserHeader.vue'; 
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Activities | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      searchTerm: '',
      selectedActivityType: '0',
      selectedFilter: 'all',
      startDate: '',
      endDate: '',
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const dd = String(today.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
    onFilterChange() {
      if (this.selectedFilter === 'custom') {
        this.startDate = this.getCurrentDate();
        this.endDate = this.getCurrentDate();
      } else {
        this.fetchItems();
      }
    },
    validateDates() {
      if (this.startDate && this.endDate && this.endDate < this.startDate) {
        this.endDate = this.startDate;
      }
      if (this.startDate && this.endDate) {
        this.fetchItems();
      }
    },
    async fetchItems() {
      this.loading = true;
      try {
        let params = {
          filter: this.selectedFilter,
          page: this.currentPage,
          limit: 20,
          search: this.searchTerm,
          activityTypeId: this.selectedActivityType
        };

        if (this.selectedFilter === 'custom') {
          params.startDate = this.startDate;
          params.endDate = this.endDate;
        }
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/activities`, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
          method: 'GET',
          params: params
        });

        this.items = response.data.items;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalItems;
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchItems();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchItems();
      }
    },
    clearSearch() {
      this.searchTerm = '';
      this.fetchItems();
    },
    convertUtcToLocal
  },
  computed: {
    formattedTotalPages() {
      return this.totalPages.toLocaleString();
    },
    formattedTotalItems() {
      return this.totalItems.toLocaleString();
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>
