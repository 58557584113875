<template>
  <div class="uk-container login-user">
    <UserHeader />
    <div class="uk-margin-top uk-margin-bottom">
      <a v-if="isAuthenticated" @click.prevent="logout">Logout</a>
    </div>
    <h1>Generate Test Results PDF</h1>
    <div>
      <button class="uk-button" @click="generatePdf">Generate and Download PDF</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    async generatePdf() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/generate-test-results-pdf`, {
          responseType: 'blob', // Important
        });

        // Create a URL for the file
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        // Set the download attribute with a filename
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'test-results.pdf');

        // Append to the document and trigger the download
        document.body.appendChild(fileLink);
        fileLink.click();

        // Clean up
        fileLink.remove();
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    },
  },
};
</script>
