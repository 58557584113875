<template>
  <div id="update-email-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title">Update Email Address</h2>
      <div v-if="serverResponse" class="uk-margin-bottom">
        <div :class="serverResponseType">
          {{ serverResponse }}
        </div>
      </div>
      <div v-if="!serverResponse">
        <div class="uk-grid" v-if="customer">
          <div v-if="customer.FirstName || customer.LastName" class="columns">
            <label>Name</label>
            <div>{{ customer.FirstName }} {{ customer.LastName }}</div>
          </div>          
          <div class="columns">
            <label>Email</label>
            <div>{{ customer.Email }} ({{ customer.Id }})</div>
          </div>
        </div>
        <form @submit.prevent="submit" class="form-modal">
          <div class="uk-inline uk-margin-bottom-small">
            <input
              class="uk-input"
              placeholder="New email address"
              v-model="newEmail"
              @input="validateEmail"
            />
            <div v-if="isEmailInvalid" class="uk-text-danger">
              Please enter a valid email address.
            </div>
          </div>
          <div class="uk-margin">
            <button class="uk-button uk-button-primary" type="submit" :disabled="isFormInvalid">Change Email Address & Send Email</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      cid: '',
      accessToken: '',
      newEmail: '',
      serverResponse: '',
      serverResponseType: '',
      isEmailInvalid: false
    };
  },
  watch: {
    customer: {
      immediate: true,
      handler(newCustomer) {
        if (newCustomer) {
          this.cid = localStorage.getItem('adminCid');
          this.accessToken = localStorage.getItem('adminAccessToken');
        }
      }
    }
  },
  computed: {
    isFormInvalid() {
      return this.isEmailInvalid || !this.newEmail;
    }
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailInvalid = !emailPattern.test(this.newEmail);
    },
    async submit() {
      if (this.isFormInvalid) return;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/change-email`, {
          id: this.customer.Id,
          email: this.customer.Email,
          newEmail: this.newEmail
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        this.serverResponse = 'The email address was updated successfully, and an email was sent to the customer.';
        this.serverResponseType = 'uk-alert-success';

        console.log('Server response:', response.data);
      } catch (error) {
        this.serverResponse = error.response ? error.response.data : error.message;
        this.serverResponseType = 'uk-alert-danger';
        console.error('Error updating email address:', this.serverResponse);
      }
    }
  }
};
</script>
