import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import AdmiOrderHistory from './components/AdmiOrderHistory.vue';
import GenerateTestResultsPDF from './components/GenerateTestResultsPDF.vue';
import LoginUser from './components/LoginUser.vue';
import LogoutUser from './components/LogoutUser.vue';
import AdminCustomers from './components/AdminCustomers.vue';
import AdminAdmins from './components/AdminAdmins.vue';
import AdminTextKits from './components/AdminTextKits.vue';
import AdminKitIds from './components/AdminKitIds.vue';
import AdminUploadTestResults from './components/AdminUploadTestResults.vue';
import AdminReceived from './components/AdminReceived.vue';
import AdminAddKitId from './components/AdminAddKitId.vue';
import AdminTestItems from './components/AdminTestItems.vue';
import AdminTestResults from './components/AdminTestResults.vue';
import AdminAllTestItems from './components/AdminAllTestItems.vue';
import AdminActivities from './components/AdminActivities.vue';
import AdminActivitiesTestResults from './components/AdminActivitiesTestResults.vue';
import AdminActivitiesCustomer from './components/AdminActivitiesCustomer.vue';

import NotFound from './components/NotFound.vue';

const routes = [
  {
    path: '/login',
    name: 'LoginUser',
    component: LoginUser
  },
  {
    path: '/logout',
    name: 'LogoutUser',
    component: LogoutUser
  },
  {
    path: '/customers',
    name: 'AdminCustomers',
    component: AdminCustomers,
    meta: { requiresAuth: true }
  },
  {
    path: '/admins',
    name: 'AdminAdmins',
    component: AdminAdmins,
    meta: { requiresAuth: true }
  },
  {
    path: '/activities',
    name: 'AdminActivities',
    component: AdminActivities,
    meta: { requiresAuth: true }
  },
  {
    path: '/activities-test-results',
    name: 'AdminActivitiesTestResults',
    component: AdminActivitiesTestResults,
    meta: { requiresAuth: true }
  },
  {
    path: '/activities-customer',
    name: 'AdminActivitiesCustomer',
    component: AdminActivitiesCustomer,
    meta: { requiresAuth: true }
  },
  {
    path: '/test-items',
    name: 'AdminTestItems',
    component: AdminTestItems,
    meta: { requiresAuth: true }
  },
  {
    path: '/kit-ids',
    name: 'AdminKitIds  ',
    component: AdminKitIds  ,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/order-history',
    name: 'AdmiOrderHistory',
    component: AdmiOrderHistory,
  },
  {
    path: '/admin/generate-test-results-pdf',
    name: 'GenerateTestResultsPDF',
    component: GenerateTestResultsPDF,
  },
  {
    path: '/upload-test-results',
    name: 'AdminUploadTestResults',
    component: AdminUploadTestResults,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-test-kits',
    name: 'AdminTextKits',
    component: AdminTextKits,
  },
  {
    path: '/',
    name: 'AdminReceived',
    component: AdminReceived,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-kit-id',
    name: 'AdminAddKitId',
    component: AdminAddKitId,
    meta: { requiresAuth: true }
  },
  {
    path: '/test-results',
    name: 'AdminTestResults',
    component: AdminTestResults,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-test-items',
    name: 'AdminAllTestItems',
    component: AdminAllTestItems,
    meta: { requiresAuth: true }
  },
  {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound
  }  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('adminAccessToken');
    const cid = localStorage.getItem('adminCid');
    if (token && cid) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/check-admin`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'cid': cid
          }
        });

        if (response.data.authenticated) {
          next();
        } else {
          next({ path: '/login', query: { redirect: to.fullPath } });
        }
      } catch (error) {
        next({ path: '/login', query: { redirect: to.fullPath } });
      }
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
