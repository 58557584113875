<template>
  <div id="edit-item-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close @click="closeModal"></button>
      <h2 class="uk-modal-title">Edit Item</h2>
      <p>TimeWaver name will be used when the Friendly Item Name is empty. Human Description will be used for pet test results when Pet Description is empty.</p>
      <div v-if="item">
        <div v-if="serverResponse" class="uk-margin-bottom">
          <div :class="serverResponseType">
            {{ serverResponse }}
          </div>
        </div>
        <div class="uk-grid" v-if="item">
          <div class="columns">
            <label>Id</label>
            <div>{{ item.ItemId }}</div>
          </div>
          <div v-if="item.ItemName" class="columns">
            <label>TimeWaver Name</label>
            <div>{{ item.ItemName }}</div>
          </div>          
        </div>

        <form @submit.prevent="submit">
          <div class="uk-margin">
            <label>Friendly Item Name</label>
            <input v-model="itemNameFriendly" class="uk-input" />
          </div>
          <div class="uk-margin">
            <label>Human Description</label>
            <textarea v-model="itemDescription" class="uk-textarea" rows="5" cols="50"></textarea>
          </div>
          <div class="uk-margin">
            <label>Pet Description</label>
            <textarea v-model="itemDescriptionPet" class="uk-textarea" rows="5" cols="50"></textarea>
          </div>
          <button type="submit" class="uk-button uk-button-primary">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      itemNameFriendly: '',
      itemDescription: '',
      itemDescriptionPet: '',
      serverResponse: '',
      serverResponseType: ''
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        if (newItem) {
          this.itemNameFriendly = newItem.ItemNameFriendly;
          this.itemDescription = newItem.ItemDescription;
          this.itemDescriptionPet = newItem.ItemDescriptionPet;
          this.serverResponse = '';
        }
      }
    }
  },
  methods: {
    async submit() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/update-item`, {
          adminGuid: this.cid,
          itemId: this.item.ItemId,
          itemNameFriendly: this.itemNameFriendly,
          itemDescription: this.itemDescription,
          itemDescriptionPet: this.itemDescriptionPet
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        this.serverResponse = 'The item was updated successfully.';
        this.serverResponseType = 'uk-alert-success';

        // Emit the updated item to the parent to update the table row
        this.$emit('item-updated', {
          ...this.item,
          ItemNameFriendly: this.itemNameFriendly,
          ItemDescription: this.itemDescription,
          ItemDescriptionPet: this.itemDescriptionPet
        });
      } catch (error) {
        this.serverResponse = error.response ? error.response.data : error.message;
        this.serverResponseType = 'uk-alert-danger';
      }
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
