<template>
  <div id="set-resend-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title">Resend Email Verification Link</h2>
      <div v-if="serverResponse" class="uk-margin-bottom">
        <div>
          {{ serverResponse }}
        </div>
      </div>
      <div v-if="!serverResponse">
        <div class="uk-grid" v-if="customer">
          <div v-if="customer.FirstName || customer.LastName" class="columns">
            <label>Name</label>
            <div>{{ customer.FirstName }} {{ customer.LastName }}</div>
          </div>          
          <div class="columns">
            <label>Email</label>
            <div>{{ customer.Email }} ({{ customer.Id }})</div>
          </div>
        </div>
        <form @submit.prevent="submitPassword" class="form-modal">
          <div class="uk-margin">
            <button class="uk-button uk-button-primary" type="submit">Send Email</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      cid: '',
      accessToken: '',
      serverResponse: '',
      serverResponseType: ''
    };
  },
  watch: {
    customer: {
      immediate: true,
      handler(newCustomer) {
        if (newCustomer) {
          this.cid = localStorage.getItem('adminCid');
          this.accessToken = localStorage.getItem('adminAccessToken');
        }
      }
    }
  },
  methods: {
    async submitPassword() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/resend-verify-email`, {
          id: this.customer.Id,
          email: this.customer.Email
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        this.serverResponse = 'Email verification link sent.';
        this.serverResponseType = 'uk-alert-success';

        console.log('Server response:', response.data);
      } catch (error) {
        this.serverResponse = error.response ? error.response.data : error.message;
        this.serverResponseType = 'uk-alert-danger';
        console.error('Error sending email verification link:', this.serverResponse);
      }
    }
  }
};
</script>
